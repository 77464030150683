import { useGlobalState, useResponsive } from '@/hooks';
import { type Asset, ResponsiveCollection } from '@/interfaces';
import Image from 'next/image';
import { GraphicImgContainer } from './GraphicImg.styles';

interface GraphicImgProps {
  image?: Asset;
  imageMobile?: Asset;
  priority?: boolean;
  includedWigdet?: boolean;
}
const GraphicImg: React.FC<GraphicImgProps> = ({ image, imageMobile, priority, includedWigdet }) => {
  const { isMobile } = useResponsive([ResponsiveCollection.Mobile]);
  const { getProperImageUrl } = useGlobalState();

  if (isMobile) {
    if (!imageMobile) return <></>;

    return (
      <GraphicImgContainer>
        <span style={{ float: 'right' }}>
          <Image
            src={getProperImageUrl(imageMobile)}
            alt={imageMobile.description || 'parcel monitor banner'}
            priority={priority}
            quality={100}
            sizes='100vw'
            width={211}
            height={147}
            style={{
              objectFit: 'contain',
              objectPosition: 'top',
            }}
          />
        </span>
      </GraphicImgContainer>
    );
  }

  if (image) {
    return (
      <GraphicImgContainer>
        <Image
          src={getProperImageUrl(image)}
          alt={image.description || 'parcel monitor banner'}
          priority={priority}
          quality={100}
          width={includedWigdet ? 720 : 500}
          height={includedWigdet ? 269 : 258.51}
          style={{
            objectFit: 'contain',
            objectPosition: 'top',
          }}
        />
      </GraphicImgContainer>
    );
  }

  return <></>;
};

export default GraphicImg;
